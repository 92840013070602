<template>
  <section  class="special_services secpadd">
   <div class="container">
     <div class="row quote1top">
       <div class="col-md-8">
         <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
           <h2>{{ $store.getters['heading/getPortalHeadings'].title }}</h2>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-md-6 col-sm-12">
         <div class="fh-service-box icon-type-theme_icon style-1">
           <span class="fh-icon"><i class="glyphicon glyphicon-globe"></i></span>
           <h4 class="service-title"><a href="#" class="link" target="_blank">{{ getTranslation('registerForOnlineBooking') }}</a></h4>
           <div class="desc">
             <p>Book, track and manage your shipments online from anywhere in the world. Cargojet.online is available in all countries, use one of the links below to get started.</p>
           </div>
           <div class="desc" >
             <router-link class="register-link" :to="{name:'portal.register.client'}" >{{ getTranslation('registerForOnlineLoginCredentials') }}</router-link>
           </div>
         </div>
       </div>
       <div class="col-md-6 col-sm-12">
         <div class="fh-service-box icon-type-theme_icon style-1">
           <span class="fh-icon"><i class="flaticon-international-delivery"></i></span>
           <h4 class="service-title"><a href="#" class="link" target="_blank">{{ getTranslation('knownShippers') }}</a></h4>
           <div class="desc">
             <p>If you need to sign up as a known shipper with Cargo Jet, or if you're already a known shipper and would like to update your company's information, please use one of the links below.</p>
           </div>
           <div class="desc">
             <router-link class="register-link" :to="{name:'portal.register.agent'}" >{{ getTranslation('registerAsAgent') }}</router-link>
           </div>
         </div>
       </div>
     </div>
   </div>
  </section>
</template>

<script>
export default {
  name: "Register",
  created() {
    this.setPortalHeadings({
      title: this.getTranslation('registration'),
      breadcrumbs: [
        {
          label: this.getTranslation('home'),
          route: '/'
        },
        {
          label: this.getTranslation('registration'),
        }
      ]
    });
  }
}
</script>

<style scoped>

</style>